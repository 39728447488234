import {
  Box,
  Backdrop,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Fade,
  Modal,
  Typography,
} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import React, {useEffect} from "react";
import OnboardingSteps from "./OnboardingSteps";
import {useDispatch, useSelector} from "react-redux";
import {changeBranch} from "src/redux/actions/authActions";
import Spinner from "src/utils/spinner/spinner";
import {getRestaurant, getRestaurantBranches} from "src/redux/actions/restaurantActions";
import {useState} from "react";
const useStyles = makeStyles()((theme) => {
  return {
    modal: {
      overflowY: "scroll",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      width: "60%",
      backgroundColor: "white",
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    root: {
      position: "relative",
      width: 345,
      height: 410,
      margin: "15px 15px 15px 0",
      padding: theme.spacing(1),
      transition: "all 250ms linear",
      "&:hover": {
        transform: "scale(1.02)",
      },
    },
  };
});

function ManageLocation() {
  const {classes} = useStyles();
  const [open, setOpen] = React.useState(false);
  const {restaurantBranches, _id} = useSelector((state) => state.restaurant);
  const dispatch = useDispatch();
  const handleOpen = () => {
    setOpen(true);
  };

  const [branches, setBranches] = useState([]);

  useEffect(() => {
    setBranches(restaurantBranches);
  }, [restaurantBranches.length]);
  const handleClose = () => {
    setOpen(false);
    dispatch(getRestaurantBranches());
  };

  return (
    <Container style={{marginTop: "20px"}}>
      {branches.length ? (
        <>
          <Box style={{width: "100%"}}>
            <Button
              variant="outlined"
              onClick={handleOpen}
              color="primary"
              style={{display: "block", marginLeft: "auto"}}
            >
              Create New Branch
            </Button>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center" flexWrap="wrap">
            {branches.length &&
              branches.map((row) => {
                if (row._id !== _id)
                  return (
                    <Card className={classes.root}>
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          alt={row.name}
                          height={240}
                          image={row.logoUrl}
                          title={row.name}
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="h2">
                            {row.name}
                          </Typography>
                          <Typography variant="body2" color="textSecondary" component="p">
                            {"Estimated On: " + row.establishmentDate}
                          </Typography>
                          <Typography variant="body2" color="textSecondary" component="p">
                            {"Address: " +
                              row.restaurantAddress.streetName +
                              "," +
                              row.restaurantAddress.streetNo +
                              "," +
                              row.restaurantAddress.city +
                              "," +
                              row.restaurantAddress.country}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                      <CardActions style={{position: "absolute", bottom: "0"}}>
                        <Button
                          size="small"
                          color="primary"
                          onClick={() => dispatch(changeBranch(row._id))}
                        >
                          OPERATE
                        </Button>
                      </CardActions>
                    </Card>
                  );
              })}
          </Box>
        </>
      ) : (
        <Spinner />
      )}
      <Modal className={classes.modal} open={open} onClose={handleClose}>
        {/* <Fade in={open}> */}
        <div className={classes.paper}>
          <OnboardingSteps newBranch={true} handleClose={handleClose} />
        </div>
        {/* </Fade> */}
      </Modal>
    </Container>
  );
}

export default ManageLocation;
