import {makeDecrypt} from "src/utils/securels";
import axios from "../../../axios";

export const setTourStatus = async (data) => {
  try {
    return await axios
      .post(
        `/employee/restaurant/disable-first-time-login/${makeDecrypt("employeeId")}`,
        {data}
      )
      .catch((error) => {
        return error.message;
      });
  } catch (error) {
    return error.message;
  }
};
