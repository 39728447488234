import {makeDecrypt} from "src/utils/securels";
import axios from "../../axios";
import {
  MAIN_MENU_LOADING,
  MAIN_MENU_LOADING_COMPLETED,
  STOP_CATEGORY_LOADING,
  UPDATE_MAIN_MENU,
} from "../types";
import {getMenuItems} from "./menuActions";
import {getRestaurant} from "./restaurantActions";

export const getMainMenus = (navigate, selectedMainMenu) => (dispatch) => {
  const restId = makeDecrypt("restaurantId");
  axios
    .get(`/mainMenu/${restId}/all-menu`, {
      headers: {Authorization: makeDecrypt("jwt")},
    })
    .then((res) => {
      if (selectedMainMenu)
        dispatch({
          type: "SELECTED_MAIN_MENU",
          payload: selectedMainMenu,
        });
      // else
      //   dispatch({
      //     type: "SELECTED_MAIN_MENU",
      //     payload: res.data[0] ? res.data[0].name : "",
      //   });

      dispatch({
        type: "SET_ALL_MAIN_MENU",
        payload: res.data,
      });
      // dispatch(setReload())
      dispatch({type: MAIN_MENU_LOADING_COMPLETED});
      dispatch({type: STOP_CATEGORY_LOADING});
    })
    .catch((error) => {
      return error.message;
    });
  // navigate('/app/products', { replace: true })
};

export const toggleMenuStatus = (data, menuId, navigate) => (dispatch) => {
  const restId = makeDecrypt("restaurantId");
  axios
    .put(`/mainMenu/main-menu/change-status/${restId}/${menuId}`, data, {
      headers: {Authorization: makeDecrypt("jwt")},
    })
    .then((res) => {
      // window.location.reload();
      dispatch(getMainMenus());
      dispatch(getMenuItems(true));
      // dispatch({type: UPDATE_MAIN_MENU, payload: res.data.menu});
    })
    .catch((error) => {
      return error.message;
    });
};

export const editMenuName = (data, menuId, navigate) => (dispatch) => {
  const restId = makeDecrypt("restaurantId");

  axios
    .put(`/mainMenu/${restId}/${menuId}/update-menu-name`, data, {
      headers: {Authorization: makeDecrypt("jwt")},
    })
    .then((data) => dispatch(getMainMenus()))
    .catch((error) => {
      return error.message;
    });
};

export const addMenu = (data, navigate, setThisReload, thisReload) => (dispatch) => {
  // dispatch({ type: LOADING_RESTAURANT })
  dispatch({type: MAIN_MENU_LOADING});

  const restId = makeDecrypt("restaurantId");
  axios
    .post(`/mainMenu/${restId}/create-menu`, data, {
      headers: {Authorization: makeDecrypt("jwt")},
    })
    .then((res) => {
      dispatch(getMainMenus());
      dispatch({type: MAIN_MENU_LOADING_COMPLETED});
      // dispatch({
      //   type: SET_RESTAURANT,
      //   payload: res.data.menuCategories
      // })
      // window.location.reload();
      // dispatch(setReload())
      // setThisReload(!thisReload);
      // dispatch(getRestaurant(navigate))
    })
    .catch((error) => {
      return error.message;
    });
  // navigate('/app/products', { replace: true })
};
