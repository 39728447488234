import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Link,
  Paper,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import React, {useEffect, useState} from "react";
import {makeStyles} from "tss-react/mui";
import ServiceAgreementDinify from "../../service agreement/ServiceAgreementDinify.pdf";
import {getAllPrices, getAllProducts} from "./helpers";
import _ from "lodash";
import PlanCard from "./PlanCard";
import AlertMsg from "../AlertMsg";
import {makeDecrypt, makeEncrypt} from "src/utils/securels";

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      height: "100%",
      overflowY: "scroll",
    },
    planWrapper: {
      display: "flex",
      flexDirection: "column",
      padding: "0 30px",
      justifyContent: "center",
    },
    buttonWrapper: {
      backgroundColor: "#fff",
      // width: '300px',
      padding: "5px",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      // border:''
      borderRadius: "35px",
    },
    plans: {
      minHeight: "400px",
      marginTop: "10px",
    },
    cardWrapper: {
      display: "flex",
      marginBottom: "30px",
    },
    // planCard: {
    //   maxHeight: "50vh",
    //   overflowY: "scroll",
    // },
    planName: {
      fontSize: "20px",
      fontWeight: "600",
      // textAlign: 'center',
    },
    price: {
      fontSize: "3vw",
      margin: "10px 0",
      marginTop: "20px",
      "&::before": {
        content: '"$"',
        fontSize: "20px",
        position: "relative",
        bottom: "20px",
        right: "5px",
      },
      // '&::after':  {
      //         content: '"/MO"',
      //         fontSize: '14px',
      //         position: 'relative',
      //         left: '5px'
      //     }
    },
    period: {
      // content: '"/MO"',
      fontSize: "14px",
      position: "absolute",
      right: "-25px",
      bottom: "10px",
    },
    pdfWrapper: {
      padding: "10px 15px",
    },
    pdfCont: {
      height: "650px",
      width: "550px",
      border: "0",
      outline: "0",
    },
    formWrapper: {
      display: "flex",
      flexDirection: "column",
    },
    agreementLink: {
      display: "flex",
      // flexDirection:'row'
      alignItems: "center",
      margin: "10px 0",
    },
    trialMsg: {
      color: "green",
      marginBottom: "10px",
    },
    checkBox: {
      "& .MuiSwitch-track": {
        backgroundColor: "#FF5757",
      },
      "& .MuiSwitch-thumb": {
        backgroundColor: "#FF5757",
      },
    },
  };
});

export default function Plan({
  setTermsAccepted,
  legalRepresentative,
  setSelectedPrice,
  selectedPrice,
  yearly,
  setYearly,
  restaurantData,
  setPlanSelected,
}) {
  const [isAgree, setIsAgree] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const [products, setProducts] = useState([]);
  const [prices, setPrices] = useState([]);
  const {classes} = useStyles(yearly);
  const [errorMessage, setErrorMessage] = useState("");
  const [isPlanSelect, setIsPlanSelect] = useState(false);

  useEffect(() => {
    setPlanSelected(isPlanSelect);
  }, [isPlanSelect]);

  useEffect(() => {
    getAllProducts()
      .then((data) => {
        setProducts(data.data);
        getAllPrices()
          .then((data) => {
            makeDecrypt("country") == "CANADA"
              ? setPrices(_.orderBy(data.data, ["unit_amount"], ["asc"]))
              : setPrices(_.orderBy(data.items, ["unit_amount"], ["asc"]));
            setYearly(!yearly);
          })
          .catch((error) => setErrorMessage("error.message"));
      })
      .catch((error) => setErrorMessage(error.message));
  }, []);

  const [currentPrices, setCurrentPrices] = useState([]);

  useEffect(() => {
    let tempPrices = [];
    if (prices.length && yearly) {
      for (const price of prices) {
        if (makeDecrypt("country") === "CANADA") {
          if (price.recurring.interval === "year") {
            tempPrices.push(price);
          }
        } else if (makeDecrypt("country") === "INDIA") {
          if (price.period === "yearly") {
            tempPrices.push(price);
          }
        }
      }
    } else {
      for (const price of prices) {
        if (makeDecrypt("country") === "CANADA") {
          if (price.recurring.interval === "month") {
            tempPrices.push(price);
          }
        } else if (makeDecrypt("country") === "INDIA") {
          if (price.period === "monthly") {
            tempPrices.push(price);
          }
        }
      }
    }
    setCurrentPrices(tempPrices);
  }, [yearly]);

  const handleClick = (price, name) => {
    price.name = name;
    setSelectedPrice(price);
    makeEncrypt("priceId", price.id);
    setIsPlanSelect(true);
  };

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.planWrapper}>
          <Box display={"flex"} justifyContent="space-between" alignItems={"center"}>
            <Typography variant="h1">Choose your plan</Typography>
            <Box className={classes.buttonWrapper}>
              <Typography
                style={{
                  color: yearly ? "inherit" : "#FF5757",
                }}
                onClick={() => setYearly(false)}
                className={classes.toggleButtons}
                variant="h5"
              >
                Monthly
              </Typography>
              <Switch
                checked={yearly}
                onChange={(e) => setYearly(e.target.checked)}
                color="primary"
                name="checkedB"
                inputProps={{"aria-label": "primary checkbox"}}
                className={classes.checkBox}
              />
              <Typography
                style={{
                  color: yearly ? "#FF5757" : "inherit",
                }}
                onClick={() => setYearly(true)}
                className={classes.toggleButtons}
                variant="h5"
              >
                Yearly
              </Typography>
            </Box>
          </Box>

          <Grid
            container
            alignItems="center"
            justifyContent="center"
            spacing={6}
            className={classes.plans}
          >
            {currentPrices.length ? (
              currentPrices.map((item, index) => {
                if (makeDecrypt("country").toLowerCase() === "canada") {
                  if (item.active)
                    return (
                      <Grid item className={classes.planCard} key={item}>
                        <PlanCard
                          item={item}
                          selectedPrice={selectedPrice}
                          products={products}
                          handleClick={handleClick}
                          setIsPlanSelect={setIsPlanSelect}
                        />
                      </Grid>
                    );
                } else if (makeDecrypt("country").toLowerCase() == "india") {
                  if (item.item.active) {
                    return (
                      <Grid item className={classes.planCard} key={item}>
                        <PlanCard
                          item={item}
                          selectedPrice={selectedPrice}
                          products={products}
                          handleClick={handleClick}
                          setIsPlanSelect={setIsPlanSelect}
                        />
                      </Grid>
                    );
                  }
                }
              })
            ) : (
              <CircularProgress style={{color: "black"}} />
            )}
          </Grid>
        </Box>

        <AlertMsg alertMessage={errorMessage} />
      </Box>
    </>
  );
}
