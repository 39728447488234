import {
  Box,
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Table,
  Typography,
  dialogActionsClasses,
  CircularProgress,
  Grid,
  TextField,
  Divider,
  Snackbar,
  Alert,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  createDashboardLink,
  createNewCustomer,
  createOnboardingLink,
} from "src/redux/actions/restaurantActions";
import axios from "src/axios";
import {makeStyles} from "tss-react/mui";
import {getAllCoupons, getAllTaxRates, getCouponData, getPromoCodes} from "./helpers";
import "../../styles/card.css";
import {FaCcVisa as faccvisa} from "react-icons/fa";
import AlertMsg from "../AlertMsg";
import {useNavigate} from "react-router";
import {onBoardRestaurant} from "src/redux/actions/authActions";
import {Link} from "react-router-dom";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {loadStripe} from "@stripe/stripe-js";
import {CardElement, Elements, useStripe, useElements} from "@stripe/react-stripe-js";
import axiosInstance from "src/axios";
import visalogo from "../../Images/visa.png";
import mastercardlogo from "../../Images/mastercard.png";
import americanExpresslogo from "../../Images/american-express.png";
import {deleteFromLocal, makeDecrypt, makeEncrypt} from "src/utils/securels";
import Stripe from "stripe";

/* global Stripe */
let stripe = Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      padding: "10px 30px",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      overflowY: "scroll",
    },
    tableWrapper: {
      padding: "20px 10px 0px",
    },
    table: {
      maxWidth: "450px",
    },
    tableBody: {},
    tableRow: {
      border: "none",
      alignItems: "right",
      display: "flex",
      // justifyContent: 'space-between',
    },
    tableCell: {
      display: "flex",
      width: "60%",
      padding: "20px 20px",
      borderBottom: "0",
    },
    tableCell2: {
      width: "40%",
      alignItems: "right",
      // padding: '20px 20px',
      borderBottom: "0",
    },
    stripeWrapper: {
      // minWidth: "450px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "170px",
      padding: "20px",
    },
  };
});

export default function Payment({
  setTermsAccepted,
  legalRepresentative,
  representativeName,
  setOnboardingSuccess,
  selectedPrice,
  yearly,
  setYearly,
  logoImage,
  restaurantData,
  newBranch,
  handleClose,
}) {
  let elements = stripe.elements();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {classes} = useStyles();
  const [loader, setLoader] = useState(false);
  const [card, setCard] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [availablePromoCodes, setAvailablePromoCodes] = useState([]);
  const [inputPromo, setInputPromo] = useState("");
  const [promoValid, setPromoValid] = useState();
  const [showPromoMsg, setShowPromoMsg] = useState();
  const [showInput, setShowInput] = useState(false);
  const [serviceFee, setServiceFee] = useState();
  const {currencySign} = useSelector((state) => state.restaurant);

  const [showErrorMsg, setShowErrorMsg] = useState();
  // let card;
  useEffect(() => {
    if (makeDecrypt("country") == "CANADA") {
      var style = {
        base: {
          iconColor: "#666EE8",
          color: "#31325F",
          lineHeight: "40px",
          fontWeight: 300,
          fontFamily: "Helvetica Neue",
          fontSize: "15px",

          "::placeholder": {
            color: "#CFD7E0",
          },
        },
      };

      let temp1 = elements.create("cardNumber", {
        style: style,
      });
      let temp2 = elements.create("cardExpiry", {
        style: style,
      });
      let temp3 = elements.create("cardCvc", {
        style: style,
      });
      temp1.mount("#card-number");
      temp2.mount("#card-expiry");
      temp3.mount("#card-cvc");
      setCard(temp1);
    }
  }, []);

  const [taxRates, setTaxRates] = useState([]);
  const [coupon, setCoupon] = useState(null);
  useEffect(() => {
    if (makeDecrypt("country").toLowerCase() == "canada") {
      setRows([]);
      const doAction = () => {
        getAllTaxRates()
          .then((data) => {
            setTaxRates(data.data);
            // getAllCoupons().then((data) => {
            //   setCoupon(data.data[0]);
            // });
          })
          .catch((error) => setAlertMessage(error.message));
      };
      return doAction();
    }
  }, []);

  function createData(name, amount) {
    return {name, amount};
  }

  useEffect(() => {
    getPromoCodes()
      .then((data) => {
        setAvailablePromoCodes(data.data);
        // setCoupon(data.data[0]);
      })
      .catch((err) => {
        setAvailablePromoCodes();
      });
  }, []);

  const [rows, setRows] = useState([]);

  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);

  const calculatePercentage = (percent, row, calculateTotal) => {
    let value = (percent * subTotal) / 100;

    return value;
  };
  useEffect(() => {
    if (taxRates.length)
      for (const rate of taxRates) {
        setRows((prevValues) => [
          ...prevValues,
          createData(
            `${rate.display_name} (${rate.percentage}% )`,
            calculatePercentage(rate.percentage, "coupon")
          ),
        ]);
      }
  }, [taxRates]);

  useEffect(() => {
    let tempRows = rows.filter((row) => !row.name.includes("Discount"));

    if (coupon?.valid) {
      tempRows.push(
        createData(
          `Discount (${coupon.percent_off}% )`,
          calculatePercentage(coupon.percent_off)
        )
      );
      setPromoValid(true);
    } else {
      setPromoValid(false);
    }
    setRows(tempRows);
  }, [coupon?.id]);

  useEffect(() => {
    if (coupon?.metadata?.application_service_fee) {
      setServiceFee(coupon.metadata.application_service_fee);
    } else {
      axiosInstance
        .post("/price/get-service-fee", {plan: selectedPrice.name})
        .then((response) => {
          setServiceFee(response.data.application_service_fee);
        })
        .catch((err) => {
          setAlertMessage(err.message);
        });
    }
  }, [coupon]);

  useEffect(() => {
    // setTotal(createData('Total',0))

    if (selectedPrice.recurring && selectedPrice.recurring.aggregate_usage) {
      setSubTotal(0);
    } else {
      setSubTotal(
        (makeDecrypt("country") == "CANADA"
          ? selectedPrice.unit_amount
          : selectedPrice.item.amount) / 100
      );
    }
    setRows((prevValues) => [
      ...prevValues,
      createData(
        "Subtotal",
        (makeDecrypt("country") == "CANADA"
          ? selectedPrice.recurring && selectedPrice.recurring.aggregate_usage
            ? 0
            : selectedPrice.unit_amount
          : selectedPrice.item.amount) / 100
      ),
    ]);
  }, [selectedPrice]);

  useEffect(() => {
    let tempTotal = 0;
    if (rows.length == 4 || rows.length == 3 || rows.length == 1) {
      if (selectedPrice.recurring && selectedPrice.recurring.aggregate_usage) {
        tempTotal = 0;
      } else {
        for (const row of rows) {
          if (!row.name.includes("Discount")) {
            tempTotal = tempTotal + row.amount;
          } else {
            tempTotal = tempTotal - row.amount;
          }
        }
      }

      setTotal(tempTotal);
    }
  }, [rows]);

  function displayError(event) {
    // changeLoadingStatePrices(false);
    let displayError = document.getElementById("card-element-errors");
    if (event.error) {
      displayError.textContent = event.error.message;
    } else {
      displayError.textContent = "";
    }
  }

  const handleCardSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);

    const customerData = await createNewCustomer(restaurantData.email);
    makeEncrypt("customerId", customerData.customerId);
    // Set up payment method for recurring usage
    let billingName = representativeName;

    //   let priceId = document.getElementById('priceId').innerHTML.toUpperCase();
    let priceId = makeDecrypt("priceId");

    stripe
      .createPaymentMethod({
        type: "card",
        card: card,
        billing_details: {
          name: billingName,
        },
      })
      .then((result) => {
        if (result.error) {
          displayError(result);
        } else {
          dispatch(
            onBoardRestaurant(restaurantData, logoImage, navigate, newBranch, () => {
              createSubscription({
                customerId: customerData.customerId,
                paymentMethodId: result.paymentMethod.id,
                priceId: priceId,
                newBranch,
              });
            })
          );
        }
      });
  };

  const payWithRazorPay = async () => {
    setLoader(true);
    let subscriptionId;

    const showPaymentOptions = () => {
      rzp1.open();
    };

    await axios
      .post("/rzrpay/subscribe-with-rzrpay", {planId: makeDecrypt("priceId")})
      .then((response) => {
        options;
        subscriptionId = response.data.id;
        let subscriptionLink = response.data.short_url;
        dispatch(
          onBoardRestaurant(
            restaurantData,
            logoImage,
            navigate,
            newBranch,
            showPaymentOptions,
            subscriptionLink
          )
        );
      });

    var options = {
      key: process.env.REACT_APP_RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
      amount: total * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      // currency: "INR",
      name: "test",
      description: "Test Transaction",
      image: "https://example.com/your_logo",
      subscription_id: subscriptionId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: function (response) {
        let tempRestId;
        if (newBranch) tempRestId = makeDecrypt("branchRestaurantId");
        else tempRestId = makeDecrypt("restaurantId");

        axios
          .put(
            `/restaurant/restaurant-details/${tempRestId}`,
            {
              isVerified: true,
              dinify_payment_plan: {
                subscription_id: subscriptionId,
                plan_id: selectedPrice.id,
                plan_name: selectedPrice.name,
                application_service_fee: serviceFee,
                subscription_fee: parseFloat(selectedPrice.item.amount).toFixed(2),
                push_to_customer: true,
              },
            },
            {headers: {Authorization: makeDecrypt("jwt")}}
          )
          .then((data) => {
            localStorage.removeItem("priceId");
            localStorage.removeItem("restData");
            localStorage.removeItem("legalRepresentative");
            localStorage.removeItem("branchRestaurantId");
            setLoader(false);
            setOnboardingSuccess(true);
            if (!newBranch) window.location.replace("/dashboard");
            else handleClose();
          })
          .catch((error) => {
            setAlertMessage(error.message);
            setLoader(false);
            setOnboardingSuccess(false);
          });
      },
      prefill: {
        name: "test",
        email: "test@example.com",
        contact: "9999999999",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      setShowErrorMsg(true);
    });
  };

  const createSubscription = async ({
    customerId,
    paymentMethodId,
    priceId,
    newBranch,
  }) => {
    return await axios
      .post("/stripe/create-subscription", {
        customerId: customerId,
        paymentMethodId: paymentMethodId,
        priceId: priceId,
        yearly: yearly,
        coupon: coupon,
      })
      .then((response) => {
        return response.data;
      })
      // If the card is declined, display an error to the user.
      .then((result) => {
        if (result.error) {
          // The card had an error when trying to attach it to a customer.
          throw result;
        }

        return result;
      })
      // Normalize the result to contain the object returned by Stripe.
      // Add the additional details we need.
      .then((result) => {
        let tempRestId;
        if (newBranch) tempRestId = makeDecrypt("branchRestaurantId");
        else tempRestId = makeDecrypt("restaurantId");

        axios
          .put(
            `/restaurant/restaurant-details/${tempRestId}`,
            {
              isVerified: true,
              dinify_payment_plan: {
                subscription_id: result.id,
                plan_id: selectedPrice.id,
                plan_name: selectedPrice.name,
                application_service_fee: serviceFee,
                subscription_fee: parseFloat(selectedPrice.unit_amount / 100).toFixed(2),
                push_to_customer: true,
                subscription_item_id: result.items.data[0]?.id,
                usage_type: result.items.data[0]?.plan.usage_type,
              },
            },
            {headers: {Authorization: makeDecrypt("jwt")}}
          )
          .then((data) => {
            localStorage.removeItem("priceId");
            localStorage.removeItem("restData");
            localStorage.removeItem("legalRepresentative");
            localStorage.removeItem("branchRestaurantId");
            setLoader(false);
            setOnboardingSuccess(true);
            if (!newBranch) window.location.replace("/dashboard");
            else handleClose();
          })
          .catch((error) => {
            setAlertMessage(error.message);
            setLoader(false);
            setOnboardingSuccess(false);
          });

        return {
          paymentMethodId: paymentMethodId,
          priceId: priceId,
          subscription: result,
        };
      })
      .catch((error) => {
        setLoader(false);

        setAlertMessage(error.message);
      });
  };

  useEffect(() => {
    if (makeDecrypt("country") == "CANADA") {
      let child = document.createElement("FaCcVisa");
      document.querySelector(".brand").appendChild(child);
    }
  }, []);

  const handleApply = () => {
    setCoupon();
    let tempCoupon = availablePromoCodes.find((promo) => promo.code == inputPromo);
    setShowPromoMsg(true);

    if (tempCoupon) {
      tempCoupon.active ? setCoupon(tempCoupon.coupon) : setPromoValid(false);
    } else {
      setPromoValid(false);
    }
  };

  return (
    <Box className={classes.root} boxShadow="3">
      <Box>
        <Typography variant="h1">Checkout</Typography>
      </Box>
      <Grid container spacing={3} sx={{mt: 1}}>
        {/* <TableContainer component={Paper}> */}
        <Grid item xs={12} sm={7}>
          <Box boxShadow="2" className={classes.tableWrapper}>
            <Typography variant="h3">Price breakdown</Typography>
            <Divider sx={{my: 2}} />
            <Table className={classes.table} aria-label="a dense table">
              <TableBody className={classes.tableBody}>
                {rows.map((row) => (
                  <>
                    <TableRow key={row.name} className={classes.tableRow}>
                      <TableCell className={classes.tableCell}>
                        <Typography variant="h6">{row.name}</Typography>
                      </TableCell>
                      <TableCell align="right" className={classes.tableCell2}>
                        <Typography variant="h6">
                          {(row.name.includes("Discount") ? "-" : "") +
                            `${currencySign} ` +
                            row.amount.toFixed(2)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <Divider />
                  </>
                ))}
                <Divider />
                <TableRow className={classes.tableRow}>
                  <TableCell
                    style={{fontSize: "20px", fontWeight: "500"}}
                    className={classes.tableCell}
                  >
                    <Typography variant="h4">Total</Typography>
                    <Typography
                      fontSize="18px"
                      textAlign={"center"}
                      margin="auto 0 auto 10px"
                    >
                      {makeDecrypt("country") == "INDIA" && "(including GST)"}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{fontSize: "20px", fontWeight: "500"}}
                    className={classes.tableCell2}
                  >
                    <Typography variant="h4">
                      {currencySign} {total.toFixed(2)}{" "}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {makeDecrypt("country") == "CANADA" && (
              <Box textAlign="center" sx={{py: 3}}>
                {!showInput ? (
                  total ? (
                    <Typography
                      color="primary"
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        margin: "auto",
                      }}
                      textAlign="center"
                      onClick={() => setShowInput(true)}
                    >
                      Have a promo code?
                    </Typography>
                  ) : (
                    ""
                  )
                ) : (
                  <Box
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                    sx={{display: "flex", position: "relative"}}
                  >
                    <Box display="flex">
                      <TextField
                        id="outlined-basic"
                        value={inputPromo}
                        label="Promo Code"
                        variant="outlined"
                        onChange={(e) => setInputPromo(e.target.value)}
                      />
                      <Button
                        variant="outlined"
                        onClick={() => {
                          handleApply();
                        }}
                      >
                        APPLY
                      </Button>
                    </Box>
                    <CancelOutlinedIcon
                      color="black"
                      style={{
                        cursor: "pointer",
                        margin: "auto",
                        position: "absolute",
                        right: "20px",
                      }}
                      onClick={() => setShowInput(false)}
                    />
                  </Box>
                )}
                <Box sx={{mt: 2}}>
                  {promoValid ? (
                    <Typography color="primary">
                      Nice you have saved{" "}
                      <span style={{fontSize: "18px", fontWeight: "700"}}>
                        $ {calculatePercentage(coupon.percent_off)}
                      </span>
                    </Typography>
                  ) : (
                    showPromoMsg && (
                      <Typography color="red">
                        The promo code you have entered is invalid!
                      </Typography>
                    )
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </Grid>
        {/* </TableContainer> */}
        <Grid item xs={12} sm={5}>
          {makeDecrypt("country").toLowerCase() == "canada" ? (
            <Box
              className={classes.stripeWrapper}
              boxShadow="1"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="h3" style={{marginRight: "auto"}}>
                Payment details
              </Typography>
              {/* <!-- Elements will create input elements here --> */}
              <form
                id="payment-form"
                onSubmit={handleCardSubmit}
                style={{
                  display: loader ? "none" : "",
                  width: "100%",
                  padding: "20px 10px",
                }}
              >
                <label className="card-label card-number-cont">
                  <div className="helper-logo">
                    <span className="card-helper-text" style={{marginTop: "7px"}}>
                      Card number
                    </span>
                    <div className="card-wrapper">
                      <div className="card-cont">
                        <img src={visalogo} alt="" width="30px" />
                      </div>
                      <div className="card-cont">
                        <img src={mastercardlogo} alt="" width="30px" />
                      </div>
                      <div className="card-cont">
                        <img src={americanExpresslogo} alt="" width="30px" />
                      </div>
                    </div>
                  </div>
                  <div id="card-number" className="card-number-input card-field"></div>
                  <span className="brand">
                    <i className="pf pf-credit-card" id="brand-icon"></i>
                  </span>
                </label>
                <div className="box2">
                  <label className="card-label card-expiry-cont small-box">
                    <span className="card-helper-text">Expiry date</span>
                    <div
                      id="card-expiry"
                      className="card-expiry-input card-field"
                      style={{padding: "auto 10px", fontWeight: "700"}}
                    ></div>
                  </label>
                  <label className="card-label card-cvc-cont small-box">
                    <span className="card-helper-text">CVC</span>
                    <div
                      id="card-cvc"
                      className="card-cvc-input card-field"
                      style={{padding: "auto 10px"}}
                    ></div>
                  </label>
                </div>
                {/* </div> */}

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="subscribe-btn"
                >
                  Subscribe
                </Button>
              </form>
              {loader && <CircularProgress />}
              {/* } */}
            </Box>
          ) : (
            <Box boxShadow="1" sx={{p: 2, display: "flex", justifyContent: "center"}}>
              <Button
                variant="contained"
                color={"primary"}
                onClick={() => payWithRazorPay()}
              >
                PAY USING RAZORPAY
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
      <AlertMsg alertMessage={alertMessage} />
      <Snackbar
        open={showErrorMsg}
        autoHideDuration={3000}
        onClose={() => setShowErrorMsg(false)}
      >
        <Alert onClose={() => setShowErrorMsg(false)} severity="error" variant="filled">
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
