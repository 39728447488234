import axios from "src/axios";

export const deleteNotification = (restId, notificationId) => {
  return axios
    .put(`/order/deleteNotification/${restId}/${notificationId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {});
};

export const deleleAllNotifications = (restId) => {
  return axios
    .put(`/order/deleteAllNotifications/${restId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {});
};

export const getOrderByDate = (limit, page, days, id) => {
  return axios
    .get(`/order/${id}/orders/dinify?limit=${limit}&page=${page}&days=${days}`)
    .then((res) => res.data)
    .catch((error) => {});
};

export const getTotalRevenueForClient = (amount, id, startingDate, endingDate) => {
  return axios
    .post(`/order/${id}/revenue/dinify`, {amount, startingDate, endingDate})
    .then((res) => res.data)
    .catch((error) => {});
};
