import {makeDecrypt} from "src/utils/securels";
import axios from "../../axios";

export const getAllDiscounts = () => (dispatch) => {
  return axios
    .get(`discount/get-all-discounts/${makeDecrypt("restaurantId")}`)
    .then((res) => {
      if (res.data.length)
        dispatch({
          type: "GET_ALL_DISCOUNTS",
          payload: res.data,
        });
      else {
        dispatch({
          type: "STOP_DISCOUNT_LOADING",
        });
      }
      return res.data;
    })
    .catch((error) => {
      return error.message;
    });
  // navigate("/app/customers", {replace: true});
};

export const createDiscount = (data) => (dispatch) => {
  return axios
    .post(`discount/create-discount/${makeDecrypt("restaurantId")}`, data, {
      headers: {Authorization: makeDecrypt("jwt")},
    })
    .then((res) => {
      dispatch({
        type: "ADD_DISCOUNT",
        payload: res.data,
      });
      return res.data;
    })
    .catch((error) => {
      return error.message;
    });
  // navigate("/app/customers", {replace: true});
};

export const updateDiscount = (discountId, data) => (dispatch) => {
  return axios
    .post(`discount/update-discount/${discountId}`, {data})
    .then((res) => {
      dispatch({
        type: "UPDATE_DISCOUNT",
        payload: res.data,
      });
      return res.data;
    })
    .catch((error) => {
      return error.message;
    });
  // navigate("/app/customers", {replace: true});
};

export const deleteDiscount = (discountId) => (dispatch) => {
  return axios
    .get(`discount/delete-discount/${discountId}`)
    .then((res) => {
      getAllDiscounts(dispatch).then(() => {
        return res.data;
      });
    })
    .catch((error) => {
      return error.message;
    });
  // navigate("/app/customers", {replace: true});
};
