import {makeDecrypt} from "src/utils/securels";
import axios from "../../axios";
import {GET_ALL_EMPLOYEES, LOADING_EMPLOYEE, UPDATE_EMPLOYEE} from "../types";

export const createEmployee =
  (employeeData, navigate, setAlertMsg, setShowSuccessMsg, setShowErrorMsg) =>
  (dispatch) => {
    return axios
      .post("/employee/" + employeeData?.restId + "/create-employee", employeeData, {
        headers: {Authorization: makeDecrypt("jwt")},
      })
      .then((res) => {
        setAlertMsg("Employee created successfully!");
        setShowSuccessMsg(true);
        return res.data;
      })
      .catch((error) => {
        setAlertMsg("Unable to create employee!");
        setShowErrorMsg(true);
        return error.message;
      });
    // navigate("/app/customers", {replace: true});
  };

export const getEmployees = (restId, searchQuery) => (dispatch) => {
  dispatch({type: LOADING_EMPLOYEE});
  return axios
    .get(
      `/employee/${makeDecrypt("restaurantId")}/all-employees?name=${
        searchQuery ? searchQuery : ""
      }`,
      {
        headers: {Authorization: makeDecrypt("jwt")},
      }
    )
    .then((res) => {
      dispatch({
        type: GET_ALL_EMPLOYEES,
        payload: res.data,
      });
    })
    .catch((error) => {
      return error.message;
    });
};

export const updateEmployee = (employeeData, navigate) => (dispatch) => {
  const restId = "60b8757def171d19d4a73c50";
  const employeeId = "";
  axios
    .post(`/restaurant/${restId}/update-employee/${employeeId}`, employeeData, {
      headers: {Authorization: makeDecrypt("jwt")},
    })
    .then((res) => {
      dispatch({
        type: UPDATE_EMPLOYEE,
        payload: res.data.result,
      });
    })
    .catch((error) => {
      return error.message;
    });
  navigate("/app/customers", {replace: true});
};

export const deleteEmployee = (employeeId, navigate) => (dispatch) => {
  const restId = makeDecrypt("restaurantId");
  return axios
    .delete(`/employee/${restId}/delete-employee/${employeeId}`, {
      headers: {Authorization: makeDecrypt("jwt")},
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.message;
    });
};
