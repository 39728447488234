import {ThemeProvider, StyledEngineProvider} from "@mui/material/styles";
import jwtDecode from "jwt-decode";
import "react-perfect-scrollbar/dist/css/styles.css";
import {useSelector} from "react-redux";
import {useRoutes} from "react-router-dom";
import routes from "src/routes";
import theme from "src/theme";
import {logoutAction} from "./redux/actions/authActions";
import store from "./redux/store";
import {SET_AUTHENTICATED} from "./redux/types";
import axios from "axios";
import "./styles/app.css";
import {makeDecrypt} from "./utils/securels";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from 'firebase/messaging';
import { getAnalytics } from "firebase/analytics";
import { useDispatch } from "react-redux";
import { updateRestaurant } from "src/redux/actions/restaurantActions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB3LZjKonBqqhSHVtPB3s7_2NCv3GHVP8I",
  authDomain: "dinify-7f7d4.firebaseapp.com",
  databaseURL: "https://dinify-7f7d4-default-rtdb.firebaseio.com",
  projectId: "dinify-7f7d4",
  storageBucket: "dinify-7f7d4.appspot.com",
  messagingSenderId: "553417738260",
  appId: "1:553417738260:web:c20c09764a95e46cd2bf01",
  measurementId: "G-YN4B41FWT8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const analytics = getAnalytics(app);

const token = makeDecrypt("jwt");

if (token) {
  const decodedToken = jwtDecode(token);
  if (decodedToken.exp * 1000 < Date.now()) {
    store.dispatch(logoutAction());
    window.location.href = "/login";
  } else {
    store.dispatch({type: SET_AUTHENTICATED});
    axios.defaults.headers.common["Authorization"] = token;
  }
}

const App = () => {
  const {authenticated} = useSelector((state) => state.auth);

  const routing = useRoutes(routes(authenticated));
  const dispatch = useDispatch();
  let ftoken;

  const publicVapidKey = "BPZQUyu6NrVfttHrn0nR773Y-AusCY3CkOMV0l1uItPmK4PtoZ3pYMBRva2vUXvbSlfVZmpQrl8Vecx7xihaiXE";

  if('serviceWorker' in navigator) {
      registerServiceWorker().catch(console.log)
  }

  async function registerServiceWorker() {
      const register = await navigator.serviceWorker.register('./serviceWorker.js', {
          scope: '/'
      });

      const subscription = await register.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: publicVapidKey,
      }).then((subscription) => {
        dispatch(
              updateRestaurant({
                notificationSubscriptionEndpoint: subscription
              })
            );
        // Send the subscription to your server for storage
      })
      .catch((error) => {
        console.error('Push subscription failed:', error);
      });
      // await fetch("http://localhost:3002/api/auth/subscribe", {
      //     method: "POST",
      //     body: JSON.stringify(subscription),
      //     headers: {
      //         "Content-Type": "application/json",
      //     }
      // })
  }

  // if('serviceWorker' in navigator) {
  //     registerServiceWorker().catch(console.log)
  // }

  // async function registerServiceWorker() {
  //     const register = await navigator.serviceWorker.register('./worker.js', {
  //         scope: '/'
  //     });

  //     const subscription = await register.pushManager.subscribe({
  //         userVisibleOnly: true,
  //         applicationServerKey: publicVapidKey,
  //     });

  //     await fetch("/subscribe", {
  //         method: "POST",
  //         body: JSON.stringify(subscription),
  //         headers: {
  //             "Content-Type": "application/json",
  //         }
  //     })
  // }


  // // Obtain the FCM registration token
  // getToken(messaging, { vapidKey: 'BLQhYajMMKnfeRFLtoMcy3cJfp3uitI_UfcJj99frPC8frDsBtGLf5Ol3BKD6qdR0xZN2n' })
  // .then((currentToken) => {
  //   if (currentToken) {
  //     ftoken = currentToken;
  //     console.log('FCM registration token:', currentToken);
  //     dispatch(
  //       updateRestaurant({
  //         notificationFCMToken: currentToken
  //       })
  //     );
  //     navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
  //       console.log(ftoken)
  //       serviceWorkerRegistration.pushManager.subscribe({
  //         userVisibleOnly: true,
  //         applicationServerKey: 'BLQhYajMMKnfeRFLtoMcy3cJfp3uitI_UfcJj99frPC8frDsBtGLf5Ol3BKD6qdR0xZN2n',
  //         endpoint: token
  //       })
  //       .then((subscription) => {
  //         console.log('Push subscription successful:', subscription);
  //         dispatch(
  //               updateRestaurant({
  //                 notificationSubscriptionEndpoint: subscription.endpoint
  //               })
  //             );
  //         // Send the subscription to your server for storage
  //       })
  //       .catch((error) => {
  //         console.error('Push subscription failed:', error);
  //       });
  //     });

  //   } else {
  //     console.log('No registration token available.');
  //   }
  // })
  // .catch((error) => {
  //   console.error('Error getting FCM registration token:', error);
  // });

  // function urlBase64ToUint8Array(base64String) {
  //   const padding = '='.repeat((4 - base64String.length % 4) % 4);
  //   const base64 = (base64String + padding)
  //     .replace(/\-/g, '+')
  //     .replace(/_/g, '/');
  //   const rawData = window.atob(base64);
  //   const outputArray = new Uint8Array(rawData.length);
  //   for (let i = 0; i < rawData.length; ++i) {
  //     outputArray[i] = rawData.charCodeAt(i);
  //   }
  //   return outputArray;
  // }
  

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{routing}</ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
