import axios from "../../axios";
import {makeDecrypt} from "src/utils/securels";

export const getOrdersData = async (days, page, limit, setProgress) => {
  const restId = makeDecrypt("restaurantId");
  return await axios
    .get(`/order/${restId}/${days}/completed-orders?page=${page}&limit=${limit}`, {
      headers: {Authorization: makeDecrypt("jwt")},
      onDownloadProgress: (d) => {
        if (setProgress) setProgress(Math.round((d.loaded / d.total) * 100));
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.message;
    });
};
