import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Link,
  Paper,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import React, {useEffect, useState} from "react";
import {makeStyles} from "tss-react/mui";
import ServiceAgreementDinify from "../../service agreement/ServiceAgreementDinify.pdf";
import {getAllPrices, getAllProducts} from "./helpers";
import _ from "lodash";
import AlertMsg from "../AlertMsg";
import {makeDecrypt, makeEncrypt} from "src/utils/securels";

const useStyles = makeStyles()(() => {
  return {
    root: {
      height: "100%",
      overflowY: "scroll",
      padding: "0 20px",
    },

    pdfWrapper: {
      padding: "10px 15px",
      marginBottom: "70px",
    },
    pdfCont: {
      height: "650px",
      width: "550px",
      border: "0",
      outline: "0",
    },
    formWrapper: {
      display: "flex",
      flexDirection: "column",
    },
    agreementLink: {
      display: "flex",
      // flexDirection:'row'
      alignItems: "center",
      margin: "10px 0",
    },
    trialMsg: {
      color: "green",
      marginBottom: "10px",
    },
    pdfBox: {
      margin: "auto",
      width: "100%",
      height: "90%",
      padding: "20px",
    },
  };
});

export default function Agreement({
  setTermsAccepted,
  legalRepresentative,
  setSelectedPrice,
  selectedPrice,
  yearly,
  setYearly,
  restaurantData,
}) {
  const [isAgree, setIsAgree] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const [products, setProducts] = useState([]);
  const [prices, setPrices] = useState([]);
  const {classes} = useStyles(yearly);
  const [errorMessage, setErrorMessage] = useState("");
  const [isPlanSelect, setIsPlanSelect] = useState(false);
  const pdfUrl =
    "https://dinify-public-docs.s3.us-west-2.amazonaws.com/terms_of_service.pdf";

  useEffect(() => {
    setTermsAccepted(isAgree);
  }, [isAgree]);

  useEffect(() => {
    getAllProducts()
      .then((data) => {
        setProducts(data.data);
        getAllPrices()
          .then((data) => {
            makeDecrypt("country") == "CANADA"
              ? setPrices(_.orderBy(data.data, ["unit_amount"], ["asc"]))
              : setPrices(_.orderBy(data.items, ["unit_amount"], ["asc"]));
            setYearly(!yearly);
          })
          .catch((error) => setErrorMessage("error.message"));
      })
      .catch((error) => setErrorMessage(error.message));
  }, []);

  const [currentPrices, setCurrentPrices] = useState([]);

  useEffect(() => {
    let tempPrices = [];
    if (prices.length && yearly) {
      for (const price of prices) {
        if (makeDecrypt("country") === "CANADA") {
          if (price.recurring.interval === "year") {
            tempPrices.push(price);
          }
        } else if (makeDecrypt("country") === "INDIA") {
          if (price.period === "yearly") {
            tempPrices.push(price);
          }
        }
      }
    } else {
      for (const price of prices) {
        if (makeDecrypt("country") === "CANADA") {
          if (price.recurring.interval === "month") {
            tempPrices.push(price);
          }
        } else if (makeDecrypt("country") === "INDIA") {
          if (price.period === "monthly") {
            tempPrices.push(price);
          }
        }
      }
    }
    setCurrentPrices(tempPrices);
  }, [yearly]);

  const handleClick = (price, name) => {
    price.name = name;
    setSelectedPrice(price);
    makeEncrypt("priceId", price.id);
    setIsPlanSelect(true);
  };

  return (
    <>
      <Box className={classes.root}>
        <Typography variant="h1">Terms of service</Typography>
        <Box className={classes.pdfBox}>
          <iframe src={pdfUrl} height="100%" width={"100%"} />
        </Box>

        <Box className={classes.pdfWrapper}>
          <form className={classes.formWrapper}>
            <Box className={classes.agreementLink}>
              <Tooltip title="Make sure to select plan." arrow variant="">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isAgree}
                      onChange={() => setIsAgree(!isAgree)}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label=""
                />
              </Tooltip>
              <Typography style={{marginLeft: "-10px"}}>
                I agree to the{" "}
                <a
                  href="https://dinify-public-docs.s3.us-west-2.amazonaws.com/terms_of_service.pdf"
                  style={{cursor: "pointer"}}
                  target="_blank"
                  rel="noreferrer"
                >
                  service agreement
                </a>
              </Typography>
            </Box>

            <Dialog
              open={showPdf}
              keepMounted
              onClose={() => {
                setShowPdf(false);
              }}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dialog-slide-title">
                {"Service Agreement"}
              </DialogTitle>
              <DialogContent className={classes.AgreementCont}>
                <iframe
                  src={`${ServiceAgreementDinify}#view=fitH`}
                  title="testPdf"
                  className={classes.pdfCont}
                />
              </DialogContent>
            </Dialog>
          </form>
        </Box>
        <AlertMsg alertMessage={errorMessage} />
      </Box>
    </>
  );
}
