export const countires = [
  {
    value: "CANADA",
    label: "CANADA",
  },
  {
    value: "INDIA",
    label: "INDIA",
  },
  {
    value: "NEPAL",
    label: "NEPAL",
  },
];

export const nepalStates = [
  "Arun Kshetra",
  "Janakpur Kshetra",
  "Kathmandu Kshetra",
  "Gandaki Kshetra",
  "Kapilavastu Kshetra",
  "Karnali Kshetra",
  "Mahakali Kshetra",
];

export const indiaStates = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttarakhand",
  "Uttar Pradesh",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli",
  "Daman and Diu",
  "Delhi",
  "Lakshadweep",
  "Puducherry",
];

export const canadaStates = ["British Columbia"];
