const initialState = {
  discount: [],
  loading: true,
};

export default function discountReducer(state = initialState, action) {
  switch (action.type) {
    case "START_DISCOUNT_LOADING":
      return {
        ...state,
        loading: true,
      };
    case "STOP_DISCOUNT_LOADING":
      return {
        ...state,
        loading: false,
      };
    case "ADD_DISCOUNT":
      return {
        ...state,
        discount: [...state.discount, action.payload],
        loading: false,
      };
    case "GET_ALL_DISCOUNTS":
      return {
        ...state,
        discount: action.payload.map((discount) => discount),
        loading: false,
      };
    case "UPDATE_DISCOUNT":
      return {
        ...state,
        discount: [
          ...state.discount.map((item) =>
            item._id == action.payload._id ? {...action.payload} : item
          ),
        ],
        loading: false,
      };
    case "DELETE_DISCOUNT":
      return {
        ...state,
        discount: state.discount.filter((item) => item._id !== action.payload._id),
        loading: false,
      };
    default:
      return state;
  }
}
