import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {Formik} from "formik";
import {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {Link as RouterLink, useNavigate, useLocation} from "react-router-dom";
import * as Yup from "yup";
import ForgotPasswordHelper from "src/components/account/helpers/ForgotPassword";
import {resetPasswordAction} from "src/redux/actions/authActions";
import {useDispatch} from "react-redux";
import decode from "jwt-decode";
import {Visibility, VisibilityOff} from "@mui/icons-material";

const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(false);

  const handleClickOpen = (message) => {
    setMessage(message);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    navigate("/login", {replace: true});
  };

  // const [email, setEmail] = useState('')
  // const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  // useEffect(() => {
  //   const {exp} = decode(location.pathname.split("/")[3]);
  //   if (Date.now() >= exp * 1000) {
  //     handleClickOpen("This link has been expired.");
  //   }
  // }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              confirmPassword: "",
              password: "",
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string()
                .max(255)
                .required("password is required")
                .matches(
                  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                  "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
                ),
              confirmPassword: Yup.string()
                .max(255)
                .required("Confirm password is required"),
            })}
            onSubmit={(values, {setSubmitting, setFieldError, setErrors, setStatus}) => {
              const userData = {
                id: location.pathname.split("/")[2],
                password: values.password,
                confirmPassword: values.confirmPassword,
                code: location.pathname.split("/")[3],
              };
              resetPasswordAction(userData, navigate).then((response) => {
                // navigate('/app/dashboard', { replace: true });
                handleClickOpen(response.message);
                setSubmitting(false);
              });
            }}
          >
            {({
              errors,
              error,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                {error && <div>{error}</div>}
                <Box sx={{mb: 3}}>
                  <Typography color="textPrimary" variant="h2">
                    Reset Password
                  </Typography>
                </Box>
                <FormControl
                  // className={clsx(classes.margin, classes.textField)}
                  variant="outlined"
                  fullWidth
                  style={{marginBottom: "20px"}}
                >
                  <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="Password"
                    margin="normal"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type={showPassword ? "text" : "password"}
                    value={values.password}
                    variant="outlined"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          // onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-confirm-password">
                    Confirm Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-confirm-password"
                    error={Boolean(touched.confirm_password && errors.confirm_password)}
                    fullWidth
                    helperText={touched.confirm_password && errors.confirm_password}
                    label="Confirm Password"
                    margin="normal"
                    name="confirmPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type={showConfirmPassword ? "text" : "password"}
                    value={values.confirmPassword}
                    variant="outlined"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                          // onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <Box sx={{py: 2}}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Reset Password
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default ResetPassword;
