const initialState = {
  kdsItems: [],
};

export default function kdsItemsReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_KDS_ITEMS":
      return {
        ...state,
        kdsItems: action.payload,
      };
    case "UPDATE_KDS_ITEMS": {
      let indexOfItem = state.kdsItems.findIndex(
        (item) => item._id === action.payload._id
      );
      state.kdsItems[indexOfItem] = action.payload;
      return {
        ...state,
      };
    }
    case "REMOVE_ITEM":
      return {
        ...state,
        kdsItems: state.kdsItems.filter((item) => item._id !== action.payload),
      };
    default:
      return state;
  }
}
